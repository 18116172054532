* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;  
}

html {
  scroll-behavior: smooth;
  font-size: 1rem;
  height: 100%;
  background-color: black;
}

body {
  min-height: 100%
}

h1 {
  font-size: 3vw
}

h3 {
  font-size: 1.38vw;
}

p {
  font-size: 1.18vw;
}

::-webkit-scrollbar {
  display: none
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.maintenance {
  position: relative;
}

.maintenance h1 {
  position: relative;
  transform: translate(0, 40vh);
}

.home {
  color: #fffaf0;
	/* background: linear-gradient(45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab); 
	background: linear-gradient(45deg, hsla(291, 100%, 56%, 1) 0%, hsla(187, 100%, 51%, 1) 70%, hsla(158, 100%, 50%, 1) 100%);
  */
  background: #101921;
  background-repeat: no-repeat;
  background-attachment: fixed;
	/* animation: gradient 15s ease infinite; */
  text-align: center;
  min-height: 100vh;
  position: relative;
}

.lottery-page {
  background-color: #101921;
  min-height: 100vh;
  color: #fffaf0;
  text-align: center;
}

.privacy-page {
  padding: 5vh 10vw;  
}

.privacy-page h2, .privacy-page h3 {
  margin-top: 5vh;
}

.privacy-page p {
  margin-top: 2vh;
  text-align: justify;
}

h2 {
  font-size: 2.8vw;
  margin-bottom: 2vh;
}

.description {
  min-height: 80vh;
}

.rooms {
  padding: 5vh 0;
  min-height: 100vh;
}

.rooms h1 {
  margin-top: 2vh
}

.description-box {
  margin: 4vw 10vw;  
  background: #101921;
  border-radius: 0.6vw;
  padding: 3vh 6vw;
  box-shadow: 0 0.3vw 1.5vw rgba(0, 0, 0, 1);
}

.own_solana_n {
  font-size: 2.4vw;
  margin-bottom: 1.5vw;
}

.description-box li {
  margin: 2vh 0;
}

.description-box .list {
  text-align: left;
  list-style: square;  
  font-size: 1.4vw;
  line-height: 4vh;  
  padding: 0 3vw;
}

.list_item {
  margin-bottom: 1vw !important;
}

.note {
  font-style: italic;
  margin-top: -1vw;
  margin-bottom: 2vw !important;
}

button, input {
  color: #fffaf0
}

.links {
  position: absolute;
  margin: 2rem;
  top: 0px;
  right: 0px;
}

.room-box:hover .swap_colour {
  color: hsla(158, 100%, 50%, 1);
}

.green_colour {
  color: hsla(158, 100%, 50%, 1);
}

.solswap_token {
  position: relative;
  color: #fffaf0;
  padding-right: 1.5rem;
}

.solana-link {
  color: #fffaf0
}

.solana-link:hover {
  opacity: 0.6;
}

.slider-container {
  text-align: center;
  padding: 8vh 0;  
  position: relative;
  top: 0
}

.slick-dots {
  width: 100% !important;
  left: 50%;
  transform: translate(-50%, 100%);
}

.slick-dots li {  
  width: 4.7vw !important;
  font-size: 0.94vw;
  border: 0.177vw solid #00ffa3;
  border-radius: 0.6vw;
  letter-spacing: 0.118vw;
  font-weight: bold;
  height: 1.47vw !important;
  transition-duration: 0.8s;
}

.slick-dots li::before {
  display: none;
}

.slick-dots li u {
  text-decoration: none;
}

.slick-dots li:hover {  
  transition-duration: 0.8s;
  background-color: #00ffa3;
}

.slick-dots .slick-active {
  background-color: #00ffa3
}

.slider-button {  
  position: absolute;
  z-index: 2;
  top: 0;
  cursor: pointer;
  height: 100%;  
  width: 8vw;
  background-color: #1b1b1b;
  border: none;
  opacity: 0.5;
  outline: none;
}

.slider-button:hover {
  opacity: 0.3
}

.pointer {
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  display: inline-block;
  font-size: 1.4vw;
  margin-top: 3vw;
}

.pointer:hover .down-arrow {
  border-top-color: #00ffa3;
}

.pointer:hover p {
  color: #00ffa3
}

.right {
  right: 0;
  border-bottom-left-radius: 0.6vw;
  border-top-left-radius: 0.6vw;  
}

.left {
  left: 0;
  border-bottom-right-radius: 0.6vw;
  border-top-right-radius: 0.6vw;  
}

.right-arrow {
  border-left: 1.47vw solid #fffaf0;  
}

.left-arrow {
  border-right: 1.47vw solid #fffaf0;
}

.down-arrow {
  width: 0;
  height: 0; 
  border-left: 1.2vw solid transparent;
  border-right: 1.2vw solid transparent;
  border-top: 1.65vw solid #fffaf0; 
  position: relative;
  top: 2.95vw;
}

.left-arrow, .right-arrow {
  display: inline-block;
  width: 0; 
  height: 0; 
  border-top: 1.47vw solid transparent;
  border-bottom: 1.47vw solid transparent;
}

.room-list {
  font-size: 1.18vw;
  list-style: none;
}

.room-box {  
  border-radius: 3.55vw;
  border: none;
  outline: none;
  background-color: rgb(3, 3, 129) /*hsla(187, 100%, 51%, 1) */;
  color: #fffaf0;  
  width: 90% !important;
  height: 25vw;
  cursor: pointer;
  text-decoration: none;
  position: relative;   
  margin: 1.18vw;
  box-shadow: rgba(3, 3, 129, 0.4) -0.37vw 0.37vw, rgba(3, 3, 129, 0.3) -0.74vw 0.74vw, rgba(3, 3, 129, 0.2) -0.11vw 0.11vw, rgba(3, 3, 129, 0.1) -1.475vw 1.475vw,rgba(3, 3, 129, 0.05) -1.84vw 1.84vw;
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset; */
  transition: box-shadow 0.5s ease-in-out;
}

.room-box-contents {   
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  margin: 2vh 0;
}

.room-box-contents h3 {
  padding: 1vh 3vw;
  display: flex;
}

.latest_winner {
  display: block !important;
}

.latest_winner .swap_colour {
  margin: 0.5vw 0;
  position: relative;
}

.room-box hr {
  margin: 0.5vw 0;
  color: #00ffa3;
  border-style: solid 
}

.room_value {
  position: absolute;
  right: 3vw;
}

.room-box:hover {  
  box-shadow: none;
  opacity: 0.9
}

.room-box h1 {
  margin-top: -1.4vw;
  font-size: 2.83vw;
  transition: margin 0.2s ease-in-out;
  text-shadow: 0px 8px 6px rgba(0,0,0,0.4),
             0px 8px 13px rgba(0,0,0,0.1),
             0px 18px 23px rgba(0,0,0,0.1);
}

.room-box:hover h1 {
  margin-top: 0.59vw
}

.info {
  position: absolute;
  left: 0.6vw;
  top: 0.6vw;
}

.faq-down-arrow, .faq-up-arrow {
  width: 0;
  height: 0; 
  position: absolute;
  top: 1.18vw;
  cursor: pointer;
}

.faq-right {
  right: 3.353vw
}

.faq-left {
  left: 3.353vw
}

.faq-down-arrow {
  border-left: 0.735vw solid transparent;
  border-right: 0.735vw solid transparent;
  border-top: 0.735vw solid #fffaf0;   
}

.faq-up-arrow {
  border-left: 0.735vw solid transparent;
  border-right: 0.735vw solid transparent;
  border-bottom: 0.735vw solid #fffaf0; 
}

.faq-down-arrow:hover, faq-up-arrow:hover {
  opacity: 0.5
}

.faq, .roadmap {
  padding: 5vh 10vw;
  min-height: 100vh
}

.faqs {  
  padding: 1vh 0;
  text-align: left;
}

.faq-item {    
  display: block;
  position: relative;  
  background: transparent;
  color: #fffaf0;
  cursor: pointer;
  border-radius: 2.35vw;
  border: 0.235vw solid hsla(158, 100%, 50%, 1);
  margin: 2vh 0;
  overflow: hidden;
  padding: 0 10vw;
  width: 100%;
  max-height: 9vh;
  transition: all 0.4s linear;
}

.selected {    
  max-height: 30vh;
  transition: all 0.8s ease-out;
}

.faq-item:hover:after {
  animation: shine 5s ease-in-out  infinite;
  animation-fill-mode: forwards;  
  content: "";
  position: absolute;
  top: -110%;
  left: -210%;
  width: 200%;
  height: 200%;
  background: rgba(255, 255, 255, 0.13);
  background: linear-gradient(
    to right, 
    rgba(255, 255, 255, 0.13) 0%,
    rgba(255, 255, 255, 0.13) 77%,
    rgba(255, 255, 255, 0.5) 92%,
    rgba(255, 255, 255, 0.0) 100%
  );
}

@keyframes shine{
  10% {
    top: -30%;
    left: -30%;
    transition-property: left, top;
    transition-duration: 0.7s, 0.7s;
    transition-timing-function: ease;
  }
  100% {
    top: -30%;
    left: -30%;
    transition-property: left, top;
  }
}

.question {
  padding: 2.5vh 0;
  top: 0vh;
  position: relative;
}

.answer {
  font-weight: normal;
  margin: 3vh 0;
}

.normal-text {
  font-size: 1.175vw;
  font-weight: normal;
}

.get-in-touch-links {
  margin: 2.35vw 0;
}

.get-in-touch-links a {
  margin: 0 1.18vw
}

.verification_process {
  padding: 5vh 0;
  min-height: 100vh
}

.verification_process h1 {
  margin: 1.175vw;
}

.verification {
  margin: 4vw 10vw 2vw 10vw;  
}

.verification .list {
  text-align: center;
  list-style: none;
}

@media screen and (max-width: 1200px){
  .room_value {
    right: 1vw
  }

  .room-box-contents h3 {
    padding: 1vh 1vw
  }
}


@media screen and (max-width: 600px){
  .faq, .rooms, .home, .verification, .roadmap {
    min-height: auto;
  }

.description {
  min-height: 0vh;
}

  .verification_process {
    min-height: 0vh
  }

  h1 {
    padding-top: 1vh;
    font-size: 3vh;
  }
  
  h3 {
    font-size: 2vh
  }

  .normal-text {
    font-size: 3vw;
  }

  .description-box .list {
    font-size: 1.8vh;
  }
  
  .room-box {
    height: 35vh
  }

  .room-box-contents h3 {
    font-size: 3.5vw;
  }

  .room-box h1 {
    margin-top: 0;
    font-size: 6vw;
  }
  
  .get-in-touch-links {
    margin: 2vh 0;
  }

  .get-in-touch-links a i {
    font-size: 6vw;
  }

  .own_solana_n {
    font-size: 3vh;
  }

  .pointer {
    display: none;
  }

  .slick-dots li {  
    width: 12vw !important;
    font-size: 1.5vh;
    letter-spacing: 0;
    height: 4vw !important
  }

  .down-arrow {
    border-left: 2vh solid transparent;
    border-right: 2vh solid transparent;
    border-top: 3vh solid #fffaf0; 
    top: 4vh;
  }
  
  .faq-item {    
    padding: 0 3vw;
    max-height: 12vh;
    transition: all 0.4s linear;
  }

  .faq-left, .faq-right {
    display: none;
  }

  .selected {    
    max-height: 40vh;
    transition: all 0.8s ease-out;
  }
  
  .answer {
    padding: 1vh 0;
  }
}

@media screen and (max-width: 300px) {
  .room-box h1 {
    font-size: 3.5vh !important;
    margin-bottom: 4vh !important;
  }
  
  .slick-dots li {  
    font-size: 1.2vh;
  }
}

.navbar {
  background-color: transparent;
  min-height: 10vh;
  display: flex;
  align-items: center;
  font-size: 1rem;
  position: relative;
  z-index: 2;
  top: 3vh;  
}

.footer {
  min-height: 25vh !important;
  background: linear-gradient(transparent, 50%, black);
  top: 0
}

.sol {
  transition-duration: 1s;
  font-style: italic;
  color: #fffaf0;
  text-shadow: 0px 4px 3px rgba(0,0,0,0.4),
  0px 8px 13px rgba(0,0,0,0.1),
  0px 18px 23px rgba(0,0,0,0.1);}

.swap {
  transition-duration: 1s;
  font-style: italic;
  color: hsla(158, 100%, 50%, 1);
  text-shadow: 0px 4px 3px rgba(0,0,0,0.4),
  0px 8px 13px rgba(0,0,0,0.1),
  0px 18px 23px rgba(0,0,0,0.1);}

.home-button, .footer-home-button {
  text-decoration: none;
  font-size: 3vw !important;
  display: inline-block;
}

.home-button:hover {
  opacity: 1;
}

.home-button:hover .sol, .footer-home-button:hover .sol {
  transition-duration: 1s;
  color: #00ffa3;
}

.home-button:hover .swap, .footer-home-button:hover .swap {
  transition-duration: 1s;
  color: #fffaf0
}

.navbar-left, .navbar-center, .navbar-right {
  align-items: center;
  justify-content: center;
  position: absolute;  
  display: flex
}

.navbar-left {
  left: 4vw
}

.navbar-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 3rem;
}

.navbar-right {
  right: 4.5vw;
}

.beta {
  color:lightseagreen;
  font-weight: bold;
  transform: rotate(-30deg);
  font-size: 1.2vw;
  /* margin-left: -1vw; */
  margin-top: 1vw;
  text-align: center;
}

a {
  color: #fffaf0;
  text-decoration: none;
}

.navbar-item a:hover {
  color: #00ffa3;
}

.lottery-button:hover {
  opacity: 0.7;
  color: #101921 !important
}
.navbar-item:hover .up-arrow {
  border-bottom: 1.65vw solid #00ffa3
}

.navbar-item {
  font-size: 1.2vw;
  margin-left: 2.5vw;
  text-align: center;
}

.navbar-button {
  background-color: hsla(158, 100%, 50%, 1);
  padding: 1vw 1.4vw;
  border: none;
  border-radius: 2vw;
  color: #101921;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  font-size: 1.3vw;
  cursor: pointer;  
  display: flex;
  align-items: center;
  font-style: normal
}

.navbar-button:hover {
  opacity: 0.6;
}

.room-title {
  font-size: 2rem;
}

.discnav {
  color: #fffaf0 /*#7289DA*/;
  font-size: 2.45vw;
}

.twitnav {
  color: #fffaf0 /*#00ACEE*/;
  font-size: 2.45vw;  
}

.discnav:hover, .twitnav:hover {
  color: #00ffa3;
}

.solana-img {
  align-items: center;
  font-size: 2vw;
  justify-content: center
}

.powered-by {
  display: block;
  padding-bottom: 2vh;
  color: #00ffa3
}

.solana-logo {
  width: 20vw
}

.wallet-icon {
  width: 1.77vw;
  height: 1.77vw;
  margin-right: 0.8vw
}

.up-arrow {
  width: 0;
  height: 0; 
  border-left: 1.18vw solid transparent;
  border-right: 1.18vw solid transparent;
  border-bottom: 1.65vw solid #fffaf0; 
  position: relative;
  bottom: 5vh;
}

.navbar-item .pointer {
  margin-top: 1vh
}

@media screen and (max-width: 900px){
  .navbar-item {
    display: none
  }

  .navbar, .navbar-center {
    display: block;
    position: relative;  
  }

  .footer-home-button {
    text-decoration: none;
    display: inline-block;
  }
}

@media screen and (max-width: 768px) {
  .home-button, .footer-home-button {
    font-size: 4vh !important;
  }

  .solana-img {
    font-size: 3vh;
    margin: 4vh 0
  }
  
  .solana-logo {
    width: 30vh
  }
  
}

@media screen and (max-width: 600px) {
  .footer-home-button {
    opacity: 0;
  }

  .twitnav, .discnav {
    font-size: 8vw !important;
  }
}

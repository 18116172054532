@font-face {
  font-family: Orbitron;
  src: url("fonts/Orbitron-Regular.ttf");
}

.inputs {
  padding: 1.18vw;
  text-align: center;
}

.form-button {
  font-size: 1.18vw !important;
  border-radius: 0.6vw;
  background-color: rgba(3, 3, 129);
  border-style: none;
  padding: 0.9vw;
  font-weight: bold;
  box-shadow: rgba(3, 3, 129, 0.4) -0.37vw 0.37vw,
    rgba(3, 3, 129, 0.2) -0.74vw 0.74vw, rgba(3, 3, 129, 0.05) -1.11vw 1.11vw;
  cursor: pointer;
  text-align: center;
  transition: box-shadow 0.5s ease-in-out;
}

.form-button:enabled:hover {
  opacity: 0.6;
  box-shadow: none;
}

.form-button:disabled {
  cursor: not-allowed;
}

.form-input,
.form-textarea {
  font-size: 1.2vw !important;
  border: 2px solid grey;
  background-color: rgba(3, 3, 129);
  border-radius: 0.6vw;
  padding: 0.83vw;
  font-weight: bold;
  box-shadow: rgba(3, 3, 129, 0.4) -0.37vw 0.37vw,
    rgba(3, 3, 129, 0.2) -0.74vw 0.74vw, rgba(3, 3, 129, 0.05) -1.11vw 1.11vw;
  text-align: center;
  transition: box-shadow 0.5s ease-in-out;
}

.form-input:focus,
.form-textarea:focus {
  outline: none;
  box-shadow: none;
}

.form-input::placeholder,
.form-textarea::placeholder {
  color: lightgrey;
  opacity: 0.7;
}

.copy {
  cursor: pointer;
  display: flex;
  background-color: #00ffa3;
  padding: 1.18vw;
  border-radius: 0.6vw;
  text-align: center;
  color: black;
  margin: 2vh auto;
  width: 80%;
  position: relative;
  top: -1.18vw;
}

.copy div {
  left: 0;
  right: 0;
  margin: 0 auto 0 auto;
  position: relative;
  font-size: 0.95vw;
  word-wrap: break-word;
  max-width: 100%;
}

.copy i {
  margin-left: 1.18vw;
}

.copy:active i {
  color: lightgrey;
}

.winner {
  text-align: center;
  padding: 3.55vw;
}

.winner p {
  font-size: 8vw;
}

.smaller_text {
  font-size: 2vw !important;
}

.confetti {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 80%;
}

.countdown {
  position: relative;
  font-size: 5vw;
  font-family: Orbitron;
  color: #00ffa3;
  font-style: normal;
  letter-spacing: 0.23vw;
}

.fd_countdown_container {
  margin-top: 7vw;
  margin-bottom: 7vw;
}

.fd_countdown {
  position: relative;
  font-size: 6vw;
  font-family: Orbitron;
  color: #00ffa3;
  font-style: normal;
  letter-spacing: 0.23vw;
}

.reg-timeout {
  position: absolute;
  left: 0;
  margin: 3vh 0 0 4vw;
  text-align: center;
}

.mint_price {
  position: absolute;
  right: 0;
  margin: 3vh 4vw 0 0;
  text-align: center;
}

.latest-winner-in-room {
  position: absolute;
  right: 0;
  margin: 3vh 4vw 0 0;
  text-align: center;
  border: 6px #00ffa3 solid;
  border-radius: 50%;
  width: 200px;
  height: 200px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.latest-winner-in-room h3,
.latest-winner-in-room p {
  margin: 0.7rem 0;
  font-size: 1rem;
}

hr {
  border: 0.1vw #00ffa3 double;
  background-color: #00ffa3;
  width: 100%;
}

.latest-winner-contents {
  position: relative;
}

.to-note {
  position: relative;
  left: 50%;
  transform: translate(-50%, 0);
  margin-bottom: 2.35vw;
  color: red;
  width: 45%;
}

.mobile-room-message {
  margin: 25vh 0;
}

.room-page {
  background-color: #1b1b1b;
  min-height: 100vh;
  color: #fffaf0;
  text-align: center;
}

.room_title {
  margin-top: -2.5vw;
  padding-bottom: 2vw;
}

.room-contents {
  position: relative;
  text-align: center;
  padding: 2vh 6vw;
  min-height: 100vh;
}

.winner .pointer {
  transform: translate(0, -20%);
}

.loader {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 30%;
  margin-top: 2vw;
  margin-left: auto;
  margin-right: auto;
  width: 7.375vw; /* Need a specific value to work */
}

#nft-content {
  height: 100vh;
  position: relative;
  margin-top: 5vw;
  padding: 5vw 0;
}

.nft-link {
  position: relative;
  padding: 4vw;
  cursor: pointer;
  text-decoration: none;
}

.nft-image {
  height: 20vw;
  width: auto;
  position: relative;
  left: 0;
  right: 0;
  margin: 0 auto;
  box-shadow: 0 0.3vw 1.5vw rgba(0, 0, 0, 1);
}

.nft-slides {
  margin: 0;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.nft-slides .slick-dots {
  display: none !important;
}

.participants-header {
  margin-top: 5vw;
}

.participants-grid {
  padding: 0 2vw;
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-gap: 0;
  text-align: center;
}

.no-participants {
  margin: 5vw 0;
}

.grid-header {
  font-weight: bold;
  font-size: 1.2vw;
  background-color: hsla(158, 100%, 50%, 1);
  padding: 0.6vw;
  color: black;
}

.left-header {
  border-top-left-radius: 0.6vw;
}

.right-header {
  border-top-right-radius: 0.6vw;
}

.grid-item {
  padding: 1.18vw 0;
  border: 0.118vw solid #fffaf0;
  border-top: none;
}

.left-item {
  border-bottom-left-radius: 0.6vw;
}

.right-item {
  border-bottom-right-radius: 0.6vw;
}

.registration-complete {
  padding: 6vw;
}

.time-wrapper {
  color: #fffaf0;
}

.countdown_timer {
  size: 100vw;
}

.time {
  font-size: 2vw;
}

.form-item {
  border-left: 0.118vw solid #00ffa3;
  position: relative;
  text-align: center;
  margin: 0 auto;
  width: 50%;
  font-size: 1.18vw;
  font-weight: bold;
}
.form-item:last-child {
  border: 0px;
  padding-bottom: 0;
}
.form-item:before {
  content: "";
  width: 2.36vw;
  height: 2.36vw;
  background: #00ffa3;
  border: 1px solid #00ffa3;
  border-radius: 50%;
  position: absolute;
  left: -1.3vw;
  top: -1.18vw;
}

.form-button,
.form-input {
  width: 80%;
  margin: 1.18vw;
  position: relative;
  top: -1.18vw;
}

.form-item p {
  position: relative;
  top: -1.18vw;
  padding: 0.5vh 2.5vw;
}

.reserve {
  width: 50%;
}

.form-number {
  position: absolute;
  top: -0.59vw;
  left: -0.4vw;
  color: black;
}

@media screen and (max-width: 786px) {
  h3 {
    margin: 2vh 0;
  }

  .reg-timeout {
    position: relative;
    margin: 1vh auto 1vh auto;
    right: 0;
    left: 0;
    width: 200px;
  }

  .mint_price {
    position: relative;
    margin: 1vh auto 1vh auto;
    right: 0;
    left: 0;
    width: 200px;
  }

  .latest-winner-in-room {
    position: relative;
    margin: 1vh auto 1vh auto;
  }

  .countdown {
    font-size: 5vw;
  }

  .participants-grid {
    padding: 5vh 0 0 0;
  }

  .grid-header {
    font-size: 2vw;
    text-align: center;
    position: relative;
  }

  .grid-item {
    padding: 0;
  }

  .grid-item p {
    margin: 1.5vh 0;
    max-width: 60%;
    position: relative;
    left: 50%;
    transform: translate(-50%, 0);
    word-wrap: break-word;
    font-size: 2vw;
  }
}

@media screen and (max-width: 350px) {
  .grid-header {
    font-size: 0.6rem;
  }

  .grid-item {
    font-size: 0.6rem;
  }

  .grid-item p {
    margin: 1.5vh 0;
    max-width: 50%;
    position: relative;
    left: 50%;
    transform: translate(-50%, 0);
    word-wrap: break-word;
  }
}

.react-lucky-spinner button {
  display: none;
}

.react-lucky-spinner {
  position: relative;
  padding: 10vw 0;
  text-align: center;
}
.react-lucky-spinner .slots {
  width: 30vw;
  padding: 0;
  margin: 0 auto;
  height: 10vw;
  box-shadow: 1px 1px 9px 0px #00ffa3;
  border-radius: 5px;
  border: 1px solid #00ffa3;
  overflow: hidden;
  overflow-y: hidden;
  text-align: center;
}
.react-lucky-spinner .slots .items {
  position: relative;
  left: -28px;
  display: flex;
  padding: 0;
  margin: 0;
  height: inherit;
  max-height: 100%;
  align-items: center;
  outline: none;
}
.react-lucky-spinner .slots .items li {
  list-style: none;
  width: fit-content;
  margin: 0 15px;
  min-width: 80px;
  flex-shrink: 0;
}


@media screen and (min-width: 600px) {

    .roadmap {
        white-space: nowrap;
        overflow-x: hidden;
    }

    .roadmap ol {
        font-size: 0;
        padding: 20vw 0 20% 0;
    }

    .roadmap ol li { /* actual roadmap line */
        position: relative;
        display: inline-block;
        list-style-type: none;
        width: 15%;
        height: 3px;
        background: #00ffa3;
    }

    .roadmap ol li:not(:last-child)::after {
        content: '';
        position: absolute;
        left: calc(100%);
        width: 3px;
        height: 3vw;
        background: #fffaf0;
    }

    .roadmap ol li:nth-child(odd):not(:last-child)::after {
        bottom: 50%
    }

    .roadmap ol li:nth-child(even):not(:last-child)::after {
        top: 50%
    }

    .roadmap ol li div { /* roadmap items */
        position: absolute;
        left: 3px;
        width: 200%;
        padding: 3vw 0;
        font-size: 1.5vw;
        white-space: normal;
        cursor: pointer;
        transition: all 0.5s;
    }

    .roadmap ol li div:hover {
        color: #00ffa3;
        transition: all 0.5s;
    }

    .roadmap ol li .desc {
        position: relative;
        font-size: 1vw;
        opacity: 0.7;
        margin: 1vw auto;
        left: 0;
        right: 0;
        width: 50%;
    }

    .roadmap ol li:nth-child(odd) div {
        top: -16px;
        transform: translateY(-100%);
    }

    .roadmap ol li:nth-child(even) div {
        top: calc(100% + 16px);
    }

    .roadmap time {
        display: block;
        font-size: 1.2rem;
        font-weight: bold;
        margin-bottom: 8px;
    }

}

@media screen and (max-width: 600px) {
  
    .roadmap ol {
        list-style-type: none;  
        position: relative; 
        padding: 10vw 0;     
    }
  /* The actual timeline (the vertical ruler) */
  .roadmap ol::after {
    content: '';
    position: absolute;
    width: 3px;
    background-color: #00ffa3;
    top: 0;
    bottom: 0;
    left: 50%;
    margin-left: -3px;
    max-height: 100%;
    margin: 10vw 0
  }
  
  /* Container around content */
  .roadmap ol li {
    padding: 5vw;
    position: relative;
    width: 50%;
  }
  
  /* The circles on the timeline */
  /* .roadmap ol li::after {
    content: '';
    position: absolute;
    width: 3vh;
    height: 3px;
    right: -17px;
    background-color: white;
    top: 7vh;
    z-index: 1;
  }
   */
  /* Place the container to the left */
  .left_item {
    left: 0;
  }
  
  /* Place the container to the right */
  .right_item {
    left: 50%;
  }
  /* Fix the circle for containers on the right side */
  .right_item::after {
    left: -16px;
  }
  
  /* The actual content */
  .roadmap ol li div {
    font-size: 3.5vw;
  }

  .roadmap ol li .desc {
      font-size: 2.5vw;
      opacity: 0.7;
  }
}
img {
    width: 50%;
    display: block;
    margin: 40px auto;    
}

.digits-input {
    display: block;
    margin: 1.18vw auto;
    top: -1.1vw
}
